<template>
    <b-container class="px-0" fluid>
        <b-alert v-if="$route.query?.auftragId" class="p-2 mb-1" variant="info" show>
            <span
                v-b-tooltip.hover
                title="Anm.: Es werden nur Aufträge mit diesen AuftragID(s) angezeigt."
                class="mr-1"
            >
                <font-awesome-icon :icon="['fas', 'info-circle']" />
            </span>
            <strong>INFO</strong>: AuftragID Filter gesetzt (<small>{{ $route.query.auftragId }}</small
            >)
            <b-button @click="onClickDisplayAllOrders" variant="primary" class="ml-2">Alle Aufträge anzeigen</b-button>
        </b-alert>
        <small class="font-italic text-black-50">
            Info: Die Tabelle enthält nur Aufträge mit Auftragstyp "Rollout" (Auftrag.AuftragType="Rollout").
        </small>
        <b-table
            hover
            outlined
            primary-key="auftragID"
            show-empty
            :busy="isLoadingOrders"
            :fields="ordersFields"
            :items="orders"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :tbody-tr-class="rowClass"
        >
            <template #empty>Es wurden keine Aufträge mit ID(s) {{ $route.query?.auftragId }} gefunden.</template>
            <template #head(hak)="data">
                <span v-b-tooltip.hover title="Hausanschluss" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(ts)="data">
                <span v-b-tooltip.hover title="Trafostation" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(vk)="data">
                <span v-b-tooltip.hover title="Verteilkabine" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(auftragStatusID)="data">
                <span v-b-tooltip.hover title="Status - Auftrag.AuftragStatusID" class="text-nowrap re-link-deco">
                    <b-link
                        href="https://repowerag.visualstudio.com/REtasks/_wiki/wikis/REtasks.wiki/132/State-Flow-Auftrag"
                        target="_blank"
                    >
                        {{ data.label }} <sup><font-awesome-icon :icon="['fas', 'up-right-from-square']"/></sup>
                    </b-link>
                </span>
            </template>
            <template #head(technicianID)="data">
                <span v-b-tooltip.hover title="Techniker ID" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(managerID)="data">
                <span v-b-tooltip.hover title="Leiter/Manager ID" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(fromDate)="data">
                <span v-b-tooltip.hover title="Installationszeitraum (von)" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(toDate)="data">
                <span v-b-tooltip.hover title="Installationszeitraum (bis)" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(aa)="data">
                <span v-b-tooltip.hover title="Auftrag Ausführung Data" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(asq)="data">
                <span v-b-tooltip.hover title="AuftragSyncQueue Data" class="re-link-deco">
                    {{ data.label }}
                </span>
            </template>
            <template #head(auftragComplete)>
                <span v-b-tooltip.hover title="Auftrag Complete - 3rd Party App Update" class="re-link-deco">
                    <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
                </span>
            </template>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mr-2" />
                    <strong>Aufträge werden geladen...</strong>
                </div>
            </template>
            <template #cell(auftragID)="data">
                <router-link :to="{ name: 'order-details', params: { id: data.value } }">
                    {{ data.value }}
                </router-link>
            </template>
            <template v-slot:cell(hak)="row">
                <span class="text-nowrap">
                    <router-link class="text-nowrap" :to="{ name: 'hak-details', params: { id: row.item.hakID } }">
                        {{ row.item.hak.tp }}
                    </router-link>
                    <span v-if="row.item.hak">
                        <font-awesome-icon :icon="['fas', 'info-circle']" :id="`a-${row.item.auftragID}`" />
                    </span>
                </span>
                <b-popover :target="`a-${row.item.auftragID}`" title="HA Details" triggers="focus">
                    <b-table-simple class="m-0" hover small borderless>
                        <b-tbody>
                            <b-tr>
                                <b-td>EA Permalink:</b-td>
                                <b-td>
                                    <a v-if="row.item.hak.eaPermalink" :href="row.item.hak.eaPermalink" target="_blank">
                                        link
                                    </a>
                                    <span v-else>-</span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>GIS ID:</b-td>
                                <b-td>{{ row.item.hak.gisID }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>IS-E Area ID:</b-td>
                                <b-td>
                                    <span v-if="row.item.hak.iseAreaID">{{ row.item.hak.iseAreaID }}</span>
                                    <span v-else>-</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-popover>
            </template>
            <template v-slot:cell(ts)="row">
                <span class="text-nowrap">{{ row.item.hak.ts }}</span>
            </template>
            <template v-slot:cell(vk)="row">
                <span class="text-nowrap">{{ row.item.hak.vk }}</span>
            </template>
            <template v-slot:cell(village)="row">
                <span class="text-nowrap">{{ row.item.hak.city }}</span>
                <span class="text-nowrap d-block">{{ row.item.hak.address }}</span>
            </template>
            <template #cell(auftragStatusID)="data">
                <span class="text-nowrap font-weight-bold font-italic">{{ data.value }}</span>
            </template>
            <template v-slot:cell(bemerkung)="row">
                <div class="mb-2" v-html="row.item.bemerkung" />
                <OrchestratorInfoErrors
                    v-if="row.item?.auftragSyncQueue"
                    :asq="row.item?.auftragSyncQueue"
                    :comment="row.item.bemerkung"
                />
            </template>
            <template #cell(fromDate)="data">
                <span class="text-nowrap">{{ data.value | formatDate(constants.DATE_FORMAT) }}</span>
            </template>
            <template #cell(toDate)="data">
                <span class="text-nowrap">{{ data.value | formatDate(constants.DATE_FORMAT) }}</span>
            </template>
            <template #cell(executionDatePlanned)="data">
                <span class="text-nowrap">{{ data.value | formatDate(constants.DATETIME_FORMAT) }}</span>
            </template>
            <template #cell(executionDate)="data">
                <span class="text-nowrap">{{ data.value | formatDate(constants.DATETIME_FORMAT) }}</span>
            </template>
            <template v-slot:cell(aa)="row">
                <font-awesome-icon :icon="['fas', 'info-circle']" :id="`aa-${row.item.auftragID}`" />
                <b-popover
                    :target="`aa-${row.item.auftragID}`"
                    title="Auftrag Ausführung (AA) Details"
                    triggers="focus"
                >
                    <b-table-simple class="m-0" hover small borderless>
                        <b-tbody>
                            <b-tr>
                                <b-td class="re-asq-td-first-col">
                                    <span
                                        v-b-tooltip.hover
                                        title="Status Auftrag Ausführung - Auftrag.AusfuehrungStatus?"
                                        class="re-link-deco"
                                    >
                                        Status:
                                    </span>
                                </b-td>
                                <b-td>
                                    <span v-b-tooltip.hover title="would be nice, wouldn't it ;)" class="ml-1">
                                        <font-awesome-icon :icon="['fas', 'person-digging']" />
                                    </span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <span
                                        v-b-tooltip.hover
                                        title="Beginn Auftrag Ausführung (geplant) - Auftrag.ExecutionDatePlanned"
                                        class="re-link-deco"
                                    >
                                        Beginn (geplant):
                                    </span>
                                </b-td>
                                <b-td>
                                    {{ row.item.executionDatePlanned | formatDate(constants.DATETIME_FORMAT) }}
                                </b-td>
                            </b-tr>
                            <template v-if="!auftragExecutionDataHide.includes(row.item.auftragStatusID.toUpperCase())">
                                <b-tr>
                                    <b-td>
                                        <span
                                            v-b-tooltip.hover
                                            title="Beginn Auftrag Ausführung (effektiv) - Auftrag.AusfuehrungBeginn?"
                                            class="re-link-deco"
                                        >
                                            Beginn (effektiv):
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <span v-b-tooltip.hover title="would be nice, wouldn't it ;)" class="ml-1">
                                            <font-awesome-icon :icon="['fas', 'person-digging']" />
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span
                                            v-b-tooltip.hover
                                            title="Ende Auftrag Ausführung - Auftrag.ExecutionDate"
                                            class="re-link-deco"
                                        >
                                            Ende:
                                        </span>
                                    </b-td>
                                    <b-td>
                                        {{ row.item.executionDate | formatDate(constants.DATETIME_FORMAT) }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span
                                            v-b-tooltip.hover
                                            title="Dauer der Auftrag Ausführung (hh:mm)"
                                            class="re-link-deco"
                                        >
                                            Dauer:
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <span v-b-tooltip.hover title="would be nice, wouldn't it ;)" class="ml-1">
                                            <font-awesome-icon :icon="['fas', 'person-digging']" />
                                        </span>
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                </b-popover>
            </template>
            <template v-slot:cell(asq)="row">
                <template v-if="row.item.auftragSyncQueue">
                    <font-awesome-icon
                        :icon="['fas', 'info-circle']"
                        :id="`asq-${row.item.auftragSyncQueue.auftragSyncQueueID}`"
                    />
                    <b-popover :target="`asq-${row.item.auftragSyncQueue.auftragSyncQueueID}`" triggers="focus">
                        <template #title>
                            <span v-b-tooltip.hover title="Technical Admin Details">
                                <font-awesome-icon :icon="['fas', 'screwdriver-wrench']" />
                            </span>
                            AuftragSyncQueue Details
                        </template>
                        <b-table-simple class="m-0" hover small borderless>
                            <b-tbody>
                                <b-tr>
                                    <b-td class="re-asq-td-first-col">ASQ ID:</b-td>
                                    <b-td>
                                        <span v-if="row.item.auftragSyncQueue.auftragSyncQueueID">
                                            {{ row.item.auftragSyncQueue.auftragSyncQueueID }}
                                        </span>
                                        <span v-else>-</span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Auftrag ID:</b-td>
                                    <b-td>
                                        <span v-if="row.item.auftragSyncQueue.auftragID">
                                            {{ row.item.auftragSyncQueue.auftragID }}
                                        </span>
                                        <span v-else>-</span>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <span class="font-italic font-weight-bold">Auftrag Creation (Orchestrator):</span>
                        <template v-if="!['NEW'].includes(row.item.auftragStatusID.toUpperCase())">
                            <b-table-simple class="m-0" hover small borderless>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="re-asq-td-first-col">
                                            <span
                                                v-b-tooltip.hover
                                                title="Auftrag Creation (Orchestrator) Status (asq.AuftragCreateStatus)"
                                                class="re-link-deco"
                                            >
                                                Status:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <EtlStatus
                                                :name="'Auftrag Create'"
                                                :status="row.item.auftragSyncQueue.auftragCreateStatus"
                                                detail="see details in Hangfire Job ID"
                                            />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            Hangfire Job ID:
                                        </b-td>
                                        <b-td>
                                            <HangfireLinkJob :jobId="row.item.auftragSyncQueue.auftragCreateJobId" />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            <span
                                                v-b-tooltip.hover
                                                title="Auftrag Create Orchestrator Overview"
                                                class="re-link-deco"
                                            >
                                                Overview:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <OrchestratorStatus
                                                :asq="row.item.auftragSyncQueue"
                                                :displayOrchestratorCreate="true"
                                            />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            <span
                                                v-b-tooltip.hover
                                                title="ETL453 - REtasks Rellout Auftrag Creation"
                                                class="re-link-deco"
                                            >
                                                ETL453 Details:
                                            </span>
                                        </b-td>
                                        <b-td />
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span class="text-nowrap">
                                                <span
                                                    v-b-tooltip.hover
                                                    title="Workflow Phase of ETL453 (asq.WorkflowPhase)"
                                                    class="re-link-deco"
                                                >
                                                    WP
                                                </span>
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.workflowPhase">
                                                {{ row.item.auftragSyncQueue.workflowPhase }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">IS-E Auftrag ID:</b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.iseAuftragID">
                                                {{ row.item.auftragSyncQueue.iseAuftragID }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">IS-E Auftrag Work ID:</b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.iseAuftragWorkID">
                                                {{ row.item.auftragSyncQueue.iseAuftragWorkID }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span
                                                v-b-tooltip.hover
                                                title="SMET file generated by IS-E (call from ETL453)"
                                                class="re-link-deco"
                                            >
                                                IS-E Filename (SMET):
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.filename">
                                                {{ row.item.auftragSyncQueue.filename }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                        <div class="mb-1" v-else>
                            Noch nicht gestartet.
                            <span
                                v-b-tooltip.hover
                                title="Das sollte jedoch jeden Moment geschehen -> Bitte die Seite aktualisieren (F5)."
                                class="mr-1"
                            >
                                <font-awesome-icon :icon="['fas', 'info-circle']" />
                            </span>
                        </div>
                        <span class="font-italic font-weight-bold">Auftrag Ausführung (AA):</span>
                        <template v-if="!auftragExecutionDataHide.includes(row.item.auftragStatusID.toUpperCase())">
                            <b-table-simple class="m-0" hover small borderless>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="re-asq-td-first-col">
                                            <span
                                                v-b-tooltip.hover
                                                title="file containing new(!) SMAN(s) added during execution (which have not been foreseen in the initial Auftrag) (asq.FileNameSMAN)"
                                                class="re-link-deco"
                                            >
                                                IS-E Filename (SMAN new):
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.fileNameSMAN">
                                                {{ row.item.auftragSyncQueue.fileNameSMAN }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                        <div class="mb-1" v-else>
                            Noch nicht gestartet.
                            <span
                                v-b-tooltip.hover
                                title="Diese Informationen werden angezeigt sobald der Auftrag vom Techniker ausgeführt wird (Status=in progress)."
                                class="mr-1"
                            >
                                <font-awesome-icon :icon="['fas', 'info-circle']" />
                            </span>
                        </div>
                        <span class="font-italic font-weight-bold">Auftrag Completion (Orchestrator):</span>
                        <template v-if="!auftragCompleteDataHide.includes(row.item.auftragStatusID.toUpperCase())">
                            <b-table-simple class="m-0" hover small borderless>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="re-asq-td-first-col">
                                            <span
                                                v-b-tooltip.hover
                                                title="Auftrag Completion (Orchestrator) Status (asq.AuftragCompleteStatus)"
                                                class="re-link-deco"
                                            >
                                                Status:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <EtlStatus
                                                :name="'Auftrag Complete'"
                                                :status="row.item.auftragSyncQueue.auftragCompleteStatus"
                                                detail="see details in Hangfire Job ID"
                                            />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            Hangfire Job ID:
                                        </b-td>
                                        <b-td>
                                            <HangfireLinkJob :jobId="row.item.auftragSyncQueue.auftragCompleteJobId" />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            <span
                                                v-b-tooltip.hover
                                                title="Auftrag Create Orchestrator Overview"
                                                class="re-link-deco"
                                            >
                                                Overview:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <OrchestratorStatus
                                                :asq="row.item.auftragSyncQueue"
                                                :display-orchestrator-complete="true"
                                            />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            <span
                                                v-b-tooltip.hover
                                                title="MSP - REtasks to MSP (SMARTPOWER)"
                                                class="re-link-deco"
                                            >
                                                MSP Details:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            Infos im Hangfire log
                                            <span
                                                v-b-tooltip.hover
                                                title="#18898 - AuftragCompleteOrchestrator - log important information on dedicated DB field(s)"
                                            >
                                                <font-awesome-icon :icon="['fas', 'person-digging']" />
                                            </span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>
                                            <span
                                                v-b-tooltip.hover
                                                title="ETL454 - REtasks to IS-E (XML)"
                                                class="re-link-deco"
                                            >
                                                ETL454 Details:
                                            </span>
                                        </b-td>
                                        <b-td />
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span
                                                v-b-tooltip.hover
                                                title="Status (IS-E) - asq.BackProcedureStatus/asq.BackDetail"
                                                class="re-link-deco"
                                            >
                                                Status (IS-E):
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <template v-if="row.item.auftragSyncQueue.backProcedureStatus">
                                                <span
                                                    v-if="row.item.auftragSyncQueue?.backDetail?.length > 0"
                                                    v-b-tooltip.hover
                                                    :title="row.item.auftragSyncQueue.backDetail"
                                                    class="re-link-deco"
                                                >
                                                    {{ row.item.auftragSyncQueue.backProcedureStatus }}
                                                </span>
                                                <span v-else>
                                                    {{ row.item.auftragSyncQueue.backProcedureStatus }}
                                                </span>
                                            </template>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span
                                                v-b-tooltip.hover
                                                :title="
                                                    `The modified file based on
                                                    ${
                                                        row.item.auftragSyncQueue.backFilename
                                                            ? `'${row.item.auftragSyncQueue.filename}'`
                                                            : '\'IS-E Filename (SMET) - asq.Filename\''
                                                    }`
                                                "
                                                class="re-link-deco"
                                            >
                                                BackFilename:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.backFilename">
                                                {{ row.item.auftragSyncQueue.backFilename }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span
                                                v-b-tooltip.hover
                                                :title="
                                                    `The file containing changes based on
                                                    ${
                                                        row.item.auftragSyncQueue.backFilenameSMAN
                                                            ? `'${row.item.auftragSyncQueue.filename}'`
                                                            : '\'IS-E Filename (SMET) - asq.Filename\''
                                                    }
                                                    - ToDo: provide a better description`
                                                "
                                                class="re-link-deco"
                                            >
                                                BackFilenameSMAN:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.backFilenameSMAN">
                                                {{ row.item.auftragSyncQueue.backFilenameSMAN }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span
                                                v-b-tooltip.hover
                                                :title="
                                                    `The file containing new devices (added during AA) based on
                                                    ${
                                                        row.item.auftragSyncQueue.backFilenameSMANNew
                                                            ? `'${row.item.auftragSyncQueue.fileNameSMAN}'`
                                                            : '\'IS-E Filename (SMAN new) - asq.fileNameSMAN\''
                                                    }`
                                                "
                                                class="re-link-deco"
                                            >
                                                BackFilenameSMANNew:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.backFilenameSMANNew">
                                                {{ row.item.auftragSyncQueue.backFilenameSMANNew }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="pl-3">
                                            <span
                                                v-b-tooltip.hover
                                                title="DateTime when the file was sent to IS-E FTP server"
                                                class="re-link-deco"
                                            >
                                                BackSentDate:
                                            </span>
                                        </b-td>
                                        <b-td>
                                            <span v-if="row.item.auftragSyncQueue.backSentDate">
                                                {{
                                                    row.item.auftragSyncQueue.backSentDate
                                                        | formatDate(constants.DATETIME_FORMAT)
                                                }}
                                            </span>
                                            <span v-else>-</span>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                        <div class="mb-1" v-else>
                            Noch nicht gestartet.
                            <span
                                v-b-tooltip.hover
                                title="Diese Informationen werden angezeigt sobald der Auftrag vom Techniker fertiggestellt wird (Status=completed)."
                                class="mr-1"
                            >
                                <font-awesome-icon :icon="['fas', 'info-circle']" />
                            </span>
                        </div>
                    </b-popover>
                </template>
                <span
                    v-else
                    v-b-tooltip.hover
                    :title="`AuftragSyncQueue record existiert nicht für Auftrag ${row.item.auftragID}`"
                >
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-danger" />
                </span>
            </template>
            <!-- note(udo): disabled for the moment -->
            <template v-if="false" v-slot:cell(orchestratorStatusOverview)="row">
                <!-- note(udo): disabled for the moment -->
                <OrchestratorStatus :asq="row.item.auftragSyncQueue" />
            </template>
            <template v-if="false" v-slot:cell(statusOverviewChecks)="row">
                <div v-if="['ERROR'].includes(row.item.auftragStatusID.toUpperCase())">
                    <div v-if="row.item.auftragSyncQueue.workflowPhase === null">
                        AuftragSyncQueue.WorkflowPhase = null
                    </div>
                </div>
                <div v-if="['CREATED'].includes(row.item.auftragStatusID.toUpperCase())">
                    <div v-if="row.item.auftragSyncQueue.workflowPhase === null">
                        AuftragSyncQueue.WorkflowPhase = null
                    </div>
                </div>
            </template>
            <template v-slot:cell(auftragComplete)="row">
                <div v-if="!auftragCompleteDataHide.includes(row.item.auftragStatusID.toUpperCase())" class="d-flex">
                    <div>
                        <span
                            v-if="!row.item.sendToEA"
                            v-b-tooltip.hover
                            title="REtasks to EA - Daten übertragen (ETL451b)"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" />
                        </span>
                        <span v-else v-b-tooltip.hover title="REtasks to EA - Daten noch nicht übertragen (ETL451b)">
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </span>
                    </div>
                    <div class="ml-1">
                        <span
                            v-if="!row.item.sendToISExml"
                            v-b-tooltip.hover
                            title="REtasks to IS-E (XML) - Daten übertragen (ETL454)"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" />
                        </span>
                        <span
                            v-else
                            v-b-tooltip.hover
                            title="REtasks to IS-E (XML) - Daten noch nicht übertragen (ETL454)"
                        >
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </span>
                    </div>
                    <div class="ml-1">
                        <span
                            v-if="!row.item.sendToISE"
                            v-b-tooltip.hover
                            title="REtasks to IS-E (WS) - Daten übertragen (ETL481)"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" />
                        </span>
                        <span
                            v-else
                            v-b-tooltip.hover
                            title="REtasks to  IS-E (WS) - Daten noch nicht übertragen (ETL481)"
                        >
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </span>
                    </div>
                </div>
                <span
                    v-else
                    v-b-tooltip.hover
                    title="Diese Informationen werden angezeigt sobald der Auftrag vom Techniker fertiggestellt wird (Status=completed)."
                    class="mr-1 re-link-deco"
                >
                    -
                </span>
            </template>
            <template v-slot:cell(actionDelete)="row">
                <b-button
                    v-if="['ERROR', 'CREATED'].includes(row.item.auftragStatusID.toUpperCase())"
                    :disabled="btnAuftragDeleteInProgress"
                    @click="onClickDelete(row.item.auftragID)"
                    variant="primary"
                    class="p-1"
                    size="sm"
                >
                    <font-awesome-icon :icon="['fas', 'trash']" class="mx-1" />
                </b-button>
            </template>
        </b-table>
    </b-container>
</template>

<script>
import HangfireLinkJob from "@/components/hangfire/HangfireLinkJob.vue";
import EtlStatus from "@/components/order/EtlStatus.vue";
import OrchestratorInfoErrors from "@/components/order/OrchestratorInfoErrors.vue";
import OrchestratorStatus from "@/components/order/OrchestratorStatus.vue";
import auftragsApi from "../../services/api/auftrags.api";
import constants from "@/constants/constants";

export default {
    name: "OrdersList",
    components: {
        EtlStatus,
        HangfireLinkJob,
        OrchestratorInfoErrors,
        OrchestratorStatus,
    },
    data() {
        return {
            // hide auftrag complete data if the auftrag is in the following states
            auftragCompleteDataHide: ["NEW", "CREATING", "ERROR", "CREATED", "IN PROGRESS"],
            // hide auftrag execution data if the auftrag is in the following states
            auftragExecutionDataHide: ["NEW", "CREATING", "ERROR", "CREATED"],
            btnAuftragDeleteInProgress: false,
            constants: constants,
            isLoadingOrders: true,
            orders: [],
            ordersFields: [
                { key: "auftragID", label: "ID", sortable: true },
                { key: "hak", label: "HA", sortable: true, thStyle: { width: "1px" } },
                { key: "ts", label: "TS", thStyle: { width: "1px" } },
                { key: "vk", label: "VK", thStyle: { width: "1px" } },
                { key: "village", label: "Ort", thStyle: { width: "1px" } },
                { key: "auftragStatusID", label: "Status", sortable: true, thStyle: { width: "1px" } },
                { key: "bemerkung", label: "Bemerkung" },
                { key: "technicianID", label: "T", sortable: true },
                { key: "managerID", sortable: true, label: "M" },
                { key: "fromDate", label: "Inst.(von)", sortable: true },
                { key: "toDate", label: "Inst.(bis)", sortable: true },
                { key: "executionDatePlanned", label: "Ausführung (geplant)", sortable: true },
                { key: "executionDate", label: "Ausgeführt am", sortable: true },
                { key: "aa", label: "AA", thStyle: { width: "1px" } },
                { key: "asq", label: "ASQ", thStyle: { width: "1px" } },
                // overview over the status of all involved functionalities of auftrag "ROLLOUT" process
                //{ key: "orchestratorStatusOverview", label: "", thStyle: { width: "1px" } },
                // ToDo: WIP
                //{ key: "statusOverviewChecks", label: "Check" },
                { key: "auftragComplete", label: "", thStyle: { width: "1px" } },
            ],
            sortBy: "auftragID",
            sortDesc: true,
            sortDirection: "desc",
        };
    },
    methods: {
        onClickDisplayAllOrders() {
            this.$router.push({ name: this.$route.name });
        },
        ordersGet() {
            // get auftrag IDs from query param
            this.isLoadingOrders = true;
            return auftragsApi
                .get({
                    includeAsq: true,
                    auftragID__in: this.$route.query?.auftragId,
                })
                .then((response) => {
                    this.orders = response;
                })
                .finally(() => (this.isLoadingOrders = false));
        },
        rowClass(item, type) {
            if (!item || type !== "row") return;
            let tableRowColor = "";
            switch (item.auftragStatusID.toUpperCase()) {
                case "COMPLETED":
                case "TRANSFERRED":
                    tableRowColor = "table-success";
                    break;
                case "CREATED":
                    tableRowColor = "table-info";
                    break;
                case "CREATING":
                case "TRANSFERRING":
                    tableRowColor = "table-warning";
                    break;
                case "ERROR":
                case "ERROR-TRANSFER":
                    tableRowColor = "table-danger";
                    break;
                default:
                    // do nothing
                    break;
            }
            return tableRowColor;
        },
    },
    watch: {
        "$route.query": {
            handler: function(query) {
                if (query) {
                    this.ordersGet();
                }
            },
            // keep this so we do not have to call ordersGet() in mounted()
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style>
/* does not work with "scoped" :| */
.popover {
    max-width: 600px !important;
}
.re-link-deco {
    text-decoration-line: underline;
    text-decoration-style: dotted;
}
.re-asq-td-first-col {
    width: 180px;
}
</style>
