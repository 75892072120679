<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <PhotoUpload
                v-if="actAuftragDetail"
                :auftragDetail="compAdForPhotoUpload"
                entity="devicepool"
                :entityId="Number(devicePoolId)"
                refEntity="auftragdetail"
                :refEntityId="actAuftragDetail.auftragDetailID"
                refEntityKey="end"
                folder="haks"
                :fileDescription="`Auftrag ${orderId} - ${actAuftragDetail.action?.title}`"
                :key="actAuftragDetail.stepCurrent"
                @assetItems="onAssetItems"
                @uploadCompletionStatus="onUploadCompletionStatusUpdated"
                :captureTypes="['camera', 'gallery']"
            />
            <ExecSectionErledigt
                :isBusy="compIsBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragSyncQueuesAPI from "@/services/api/auftragSyncQueues.api";
import processApi from "@/services/api/process.api";
import devicesAPI from "@/services/api/devices.api";
import iseAPI from "@/services/api/ise.api";

export default {
    name: "Step_9_1_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        PhotoUpload,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            auftragDetails631: [],
            areFlexibilitiesConfigured: false,
            assetItems: { images: [] },
            uploadStatus: false,
            devicePoolId: this.$route.params.devicePoolId,
            isBusy: false,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            orderId: this.$route.params.orderId,
        };
    },
    async destroyed() {
        // #19601 bug: images of previous AuftragDetail are loaded
        // We should do this in a common component for every page or, even better, not store the AuftragDetail in the central Vuex store, since it changes for every page
        await this.setActAuftragDetail(null);
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compAdForPhotoUpload() {
            const _ad = JSON.parse(JSON.stringify(this.actAuftragDetail));
            if (_ad?.isExecutableDetails.toLowerCase().includes("schritt 7-5-1 ")) {
                // #20381: remove this data to make photo upload work
                // note: step 7-5-1 is not a precondition for the photo upload
                _ad.isExecutable = true;
                _ad.isExecutableDetails = "";
            }
            return _ad;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.isBusy) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Daten werden gespeichert. Bitte warten...",
                });
            }

            if (this.actAuftragDetail.status === 202) {
                // step has already been completed -> "erledigt" is disabled (to prevent double completion)
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Schritt wurde bereits abgeschlossen (und darf nicht nochmals abgeschlossen werden).",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Schritt wurde noch nicht abgeschlossen.",
                });
            }

            // ToDo: move this to the backend #19180 (by potentially using precondition config)
            if (this.areFlexibilitiesConfigured) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message:
                        "Flexibilitäten sind konfiguriert (DETAILS: ad.value.areFlexibilitiesConfigured=TRUE -> gesetzt in ExecFlexibilities.vue).",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message:
                        "Flexibilitäten sind nicht konfiguriert (DETAILS: ad.value.areFlexibilitiesConfigured=FALSE -> gesetzt in ExecFlexibilities.vue).",
                });
            }

            if (this.assetItems?.images?.length > 0) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Abschlussfoto vorhanden." });
            } else {
                // no final foto (abschlussfoto) -> button "erledigt" is disabled
                preconditionsLocalDetails.push({ status: "OPEN", message: "Kein Abschlussfoto vorhanden." });
            }

            if (this.uploadStatus) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Alle Fotos hochgeladen." });
            } else {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Fotoupload noch nicht abgeschlossen." });
            }

            return preconditionsLocalDetails;
        },
        compIsBusy() {
            return this.isBusy;
        },
    },
    // ToDo: make this asynchronous -> remove async
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        const res = await auftragDetailsAPI.getByOrderIdAndStep(this.orderId, step, this.devicePoolId);
        await this.setActAuftragDetail(res);
        // convert the JSON string of auftragDetail.value into JSON object
        // note(udo): quite a hacky way of storing this information -> ToDo: #19180
        //  -> probably should be handled as a precondition of the step in which this is done
        if (this.actAuftragDetail.value) {
            const parsedJSON = JSON.parse(this.actAuftragDetail.value);
            this.areFlexibilitiesConfigured = parsedJSON?.areFlexibilitiesConfigured ?? false;
        }
        // Check case SMAN no replacement (step 6-3-, with status 200)
        this.auftragDetails631 = (
            await auftragDetailsAPI.get({
                auftragID: this.orderId,
                actionGroupSequence: 6,
                actionItemSequence: 3,
                actionDetailSequence: 1,
            })
        ).filter((ad) => ad.status === 200);
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
        onAssetItems(items) {
            this.assetItems.images.push(...items.images); // make the prop reactive
        },
        onUploadCompletionStatusUpdated(status) {
            this.uploadStatus = status;
        },
        onClickErledigt() {
            const payload = [
                {
                    op: "replace",
                    path: "/status",
                    value: 202,
                },
            ];
            this.isBusy = true;
            auftragDetailsAPI
                .patch(this.actAuftragDetail.auftragDetailID, payload)
                .then(async (res) => {
                    // save change in store
                    await this.setActAuftragDetail(res);
                })
                .then(() => {
                    // IMPORTANT: only do the following if the call was successful
                    this.completeAuftragActions();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        // ToDo: make this asynchronous -> remove async
        async sendAuftragSmanNoReplacement() {
            const devices = [];
            for (const ad of this.auftragDetails631) {
                devices.push((await devicesAPI.getSingle(ad.deviceID, { includeDeviceInfos: false })).data);
            }
            const responseData = (
                await iseAPI.postCreateAuftragNoRSE(this.orderId, moment().format(constants.DATE_FORMAT_ISO), devices)
            ).data;
            //console.log("Response from ISE API", responseData);
            return responseData;
        },
        // ToDo: make this asynchronous -> remove async
        async getAuftragSyncQueue() {
            const auftragSyncQueues = (await auftragSyncQueuesAPI.get({ auftragID: this.orderId })).data;
            if (auftragSyncQueues.length === 0) {
                this.displayToast(
                    "danger",
                    `Kein AuftragSyncQueue Eintrag für Auftrag ID ${this.orderId} gefunden. Bitte Support kontaktieren.`
                );
                return;
            }
            return auftragSyncQueues[0];
        },
        completeAuftragActions() {
            if (this.order.auftragStatusID !== "in progress") {
                // note: better safe than sorry (should not happen if "isExecutable" is working correctly)
                //   -> only allow to proceed/complete if Auftrag.AuftragStatusID="in progress"
                const toast_message = `Auftrag ${this.orderId} ist nicht in Status "in progress"/"In Bearbeitung" und kann deshalb nicht abgeschlossen werden (Auftrag.AuftragStatusID=${this.order.auftragStatusID})`;
                this.displayToast("INFO", toast_message);
                return Promise.reject(toast_message);
            }

            // check all 9-1-1 steps
            auftragDetailsAPI.getByOrderIdAndStep(this.orderId, "9-1-1", null, null, null, true).then((resp) => {
                const ad911StepsInProgress = resp.filter((ad) => ad.status !== 202);

                // check if there are uncompleted records for step 9-1-1
                if (ad911StepsInProgress.length === 0) {
                    // complete Auftrag (all GS have been installed/completed)
                    this.finalizeAuftrag();
                } else {
                    // some 9-1-1 steps are still in progress (not completed)
                    const installedGs = resp.length - ad911StepsInProgress.length;
                    const message = `${installedGs}/${resp.length} Gerätestandorten installiert.`;
                    this.displayToast("INFO", message);
                    // GS installed/completed -> navigate to GS overview(!) #18914
                    this.$router.push({ name: "execution-single", params: { orderId: this.orderId } });
                }
            });
        },
        // ToDo: make this asynchronous -> remove async
        async finalizeAuftrag() {
            // complete Auftrag (now that all GS have been installed/completed)
            const lastAuftragSyncQueue = await this.getAuftragSyncQueue();
            // ToDo: this is quite hacky -> do this properly -> #20022
            // this has to run before POST /process/auftrag-complete/{auftrag_id}
            if (this.auftragDetails631.length > 0) {
                // ToDo(clarify): handle in #20022
                // ToDo: do this in the ETL not here (if this fails here, ETL454/ETL481 might transfer incomplete data)
                // ToDo: in case to complex to move, at least make this fail-proof
                // 2-Call IF 6e if step 6-3-1 exists (Kein ersatz)
                const iseSmanReplacementResponse = await this.sendAuftragSmanNoReplacement();
                // 3-update AuftragSyncQueue.fileNameSMAN
                await auftragSyncQueuesAPI.patch(lastAuftragSyncQueue.auftragSyncQueueID, [
                    {
                        op: "replace",
                        path: "/fileNameSMAN",
                        value: iseSmanReplacementResponse.iseSmanFilename,
                    },
                ]);
            }
            processApi
                .postAuftragRolloutComplete(this.orderId)
                .then(() => {
                    this.displayToast("SUCCESS", `Auftrag ${this.orderId} erfolgreich abgeschlossen. Vielen Dank :)`);
                    // give user some time to read toast before leaving
                    setTimeout(() => {
                        // navigate to next step (after above was successful)
                        this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                    }, 3000);
                })
                .catch((err) => {
                    // note: errorInterceptor shows a toast -> also do it here to have a human-readable message
                    this.displayToast(
                        "danger",
                        `Beim Abschluss von Auftrag ${this.orderId} ist ein Fehler aufgetreten. Bitte nochmals versuchen (oder Support kontaktieren).`
                    );
                    console.log(`${err}`);
                });
        },
        onClickNext(step) {
            if (this.actAuftragDetail.status === 202) {
                // GS installed/completed -> navigate to GS overview(!) #18914
                this.$router.push({ name: "execution-single", params: { orderId: this.orderId } });
            } else {
                // GS not installed/completed -> navigate to this GS (for the current GS/device pool)
                this.navigateToStepNext = step;
            }
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
    },
};
</script>
