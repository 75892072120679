<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !compAttrsDevicePool">
        <template v-if="actAuftragDetail && compAttrsDevicePool">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-form>
                <!-- Kein Zugang -->
                <b-form-checkbox v-model="keinZugang">
                    Kein Zugang zum Gerätestandort/Tableau
                </b-form-checkbox>
                <!-- Notiz -->
                <b-form-group v-show="keinZugang" label="Grund:" class="ml-4">
                    <b-form-textarea v-model="zugangInfo" rows="3" />
                </b-form-group>
                <!-- Sicherungsanordnung: taken from net.DevicePool -->
                <div class="mt-2 font-weight-bold">Sicherungsanordnung</div>
                <b-form-radio-group v-model="hasSingleMeterFuse" stacked :disabled="keinZugang">
                    <!-- Single Fuse - 202 -->
                    <b-form-radio value="true">Einzelsicherung pro Zähler</b-form-radio>
                    <!-- Collective Fuse - 203 -->
                    <b-form-radio value="false">Gesamtsicherung pro Tableau</b-form-radio>
                </b-form-radio-group>
                <div class="mt-2">
                    <strong>Fotodokumentation</strong><br />
                    Bitte dokumentieren Sie den Gerätestandort / das Tableau mit Fotos.
                </div>
                <PhotoUpload
                    :auftragDetail="actAuftragDetail"
                    entity="devicepool"
                    :entityId="compAttrsDevicePool.devicePoolID"
                    refEntity="auftragdetail"
                    :refEntityId="actAuftragDetail.auftragDetailID"
                    refEntityKey="overview"
                    folder="haks"
                    :fileDescription="`Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title}`"
                    :key="actAuftragDetail.stepCurrent"
                    @assetItems="onAssetItems"
                    :captureTypes="['camera', 'gallery']"
                />
            </b-form>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import _ from "lodash";

import constants from "@/constants/constants";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";
import auftragsAPI from "@/services/api/auftrags.api";

export default {
    name: "Step_2_2_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        PhotoUpload,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            assetItems: null,
            devicePool: null,
            hasSingleMeterFuse: null, // loaded from DevicePool.HasSingleMeterFuse
            keinZugang: false,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            zugangInfo: "",
            smartMeters: [], // Used only to manager Zurück logic
        };
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
        }),
        compAttrsDevicePool() {
            return this.$attrs.devicePool;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.keinZugang) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Kein Zugang wurde ausgewählt." });
            } else {
                // requirements: If Kein Zugang is selected the photo is optional, but if it is not selected it is madantory (erledigt clickable only if there is at least one photo) & Sicherheitsanforderung has to be selected
                if (this.assetItems?.images.length > 0) {
                    preconditionsLocalDetails.push({ status: "FULFILLED", message: "Foto vorhanden." });
                } else {
                    // no foto -> button "erledigt" is disabled
                    preconditionsLocalDetails.push({ status: "OPEN", message: "Kein Foto vorhanden." });
                }

                if (this.hasSingleMeterFuse === null) {
                    preconditionsLocalDetails.push({
                        status: "OPEN",
                        message: "Sicherungsanordnung auswählen.",
                    });
                } else {
                    preconditionsLocalDetails.push({
                        status: "FULFILLED",
                        message: "Sicherungsanordnung ausgewählt.",
                    });
                }
            }

            return preconditionsLocalDetails;
        },
        constants() {
            return constants;
        },
        status() {
            let status = constants.auftragDetailStatus.NOT_STARTED;
            if (this.hasSingleMeterFuse === null) {
                return constants.auftragDetailStatus.NOT_STARTED;
            }
            if (this.assetItems === null || this.assetItems.images.length === 0) {
                return constants.auftragDetailStatus.NOT_STARTED;
            }
            if (this.keinZugang) {
                return constants.auftragDetailStatus.BLOCKED; // 500 - kein Zugang
            }
            if (this.hasSingleMeterFuse === true || this.hasSingleMeterFuse === "true") {
                // radio support string only
                // 4 - Einzelsicherung
                return constants.auftragDetailStatus.DONE_CONDITION_1;
            }
            if (this.hasSingleMeterFuse === false || this.hasSingleMeterFuse === "false") {
                // 5 - Gesamtsicherung
                return constants.auftragDetailStatus.DONE_CONDITION_2;
            }
            return status;
        },
    },
    async mounted() {
        await devicePoolsAPI.getSingle(this.$route.params.devicePoolId).then((resp) => {
            const devicePool = resp.data;
            this.devicePool = devicePool;
            this.zugangInfo = devicePool.zugangInfo;
            this.keinZugang = devicePool.noAccess;
            this.hasSingleMeterFuse = devicePool.hasSingleMeterFuse;
        });
        await this.loadAuftragDetail();
        // Zurück logic - Get smart meters
        const allMeters = await devicesAPI.get({
            devicePoolID: this.$route.params.devicePoolId,
            type: "Elektrizitätszähler",
            includeDeviceInfos: false,
        });
        this.smartMeters = _.filter(allMeters, function(device) {
            return device.telemeteringType > 0;
        });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        async loadAuftragDetail() {
            const pathSplit = this.$route.fullPath.split("/");
            const step = pathSplit[pathSplit.length - 1];
            await auftragDetailsAPI
                .getByOrderIdAndStep(this.$route.params.orderId, step, this.$route.params.devicePoolId)
                .then((res) => {
                    this.setActAuftragDetail(res);
                });
        },
        onAssetItems(items) {
            this.assetItems = items;
        },
        async onClickErledigt() {
            await this.save(this.status);
            if (this.keinZugang) {
                await this.$router.push({
                    name: "execution-single",
                    params: { orderId: this.$route.params.orderId },
                });
            } else {
                const payload = [
                    {
                        op: "replace",
                        path: "/auftragStatusID",
                        value: "in progress",
                    },
                ];
                auftragsAPI.patch(this.order.auftragID, payload).then(() => {
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                });
            }
        },
        async onClickNext(step) {
            await this.save(null);
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            if (this.smartMeters.length > 0) {
                this.navigateToStepPrevious = step;
            } else {
                // No smartMeters, skip 2-1-1 and goto menu
                //console.log("No smartmeter found, skip 2-1-1");
                this.$router.push({ name: "execution-single", params: { orderId: this.$route.params.orderId } });
            }
        },
        async save(status) {
            const valueNew = { form: this.form };
            const patchDoc = [];
            // Save net.DevicePool.HasSingleMeterFuse (only if modified and keinZugang=false)
            if (
                this.hasSingleMeterFuse != null &&
                this.hasSingleMeterFuse !== this.compAttrsDevicePool.hasSingleMeterFuse &&
                !this.keinZugang
            ) {
                patchDoc.push({
                    op: "replace",
                    path: "/hasSingleMeterFuse",
                    value: this.hasSingleMeterFuse,
                });
            }
            if (this.devicePool.noAccess !== this.keinZugang) {
                patchDoc.push({
                    op: "replace",
                    path: "/noAccess",
                    value: this.keinZugang,
                });
            }
            if (this.devicePool.zugangInfo !== this.zugangInfo) {
                patchDoc.push({
                    op: "replace",
                    path: "/zugangInfo",
                    value: this.keinZugang ? this.zugangInfo : "",
                });
            }
            if (patchDoc.length) {
                await devicePoolsAPI.patch(this.compAttrsDevicePool.devicePoolID, patchDoc);
            }
            // Save form values
            await auftragDetailsAPI.patchValueAndStatusOnDiff(
                this.actAuftragDetail.auftragDetailID,
                this.actAuftragDetail.value,
                valueNew,
                status
            );
        },
    },
    watch: {
        keinZugang(val) {
            if (!val) {
                this.zugangInfo = "";
            }
        },
    },
};
</script>
