<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail">
        <template v-if="actAuftragDetail">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <PhotoUpload
                :auftragDetail="actAuftragDetail"
                entity="hak"
                :entityId="order.hakID"
                refEntity="auftragdetail"
                :refEntityId="actAuftragDetail.auftragDetailID"
                folder="haks"
                :fileDescription="`Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title}`"
                @assetItems="onAssetItems"
                :key="actAuftragDetail.stepCurrent"
                :captureTypes="['camera', 'gallery']"
            />
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export default {
    name: "Step_1_1_2",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        PhotoUpload,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            assetItems: { documents: [], images: [] },
            navigateToStepNext: null,
            navigateToStepPrevious: null,
        };
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
        }),
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.assetItems.images.length > 0) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Foto vorhanden." });
            } else {
                // no foto -> button "erledigt" is disabled
                preconditionsLocalDetails.push({ status: "OPEN", message: "Kein Foto vorhanden." });
            }

            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI.getByOrderIdAndStep(this.$route.params.orderId, step).then((res) => {
            this.setActAuftragDetail(res);
        });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onAssetItems(items) {
            this.assetItems = items;
        },
        onClickErledigt() {
            const payload = [
                {
                    op: "replace",
                    path: "/status",
                    value: 201,
                },
            ];
            auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payload).then(() => {
                // on SUCCESS, navigate to next step
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            });
        },
        onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
    },
};
</script>
