<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !$attrs.devicePool">
        <template v-if="actAuftragDetail && $attrs.devicePool">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div class="mt-2">Welche Gefahren bestehen an diesem Gerätestandort/Tableau?</div>
            <b-form v-if="devicePool">
                <!-- Asbest -->
                <b-form-group label="Asbest" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="form.asbestObject"
                        :aria-describedby="ariaDescribedby"
                        name="asbest-form"
                        stacked
                    >
                        <b-form-radio
                            v-for="keyValueOption in compAsbestOptions"
                            :key="keyValueOption.keyValueOptionID"
                            :value="keyValueOption"
                            >{{ keyValueOption.text }}</b-form-radio
                        >
                    </b-form-radio-group>
                </b-form-group>
                <!-- Neutralleiterabschlaufung -->
                <b-form-group label="Neutralleiterabschlaufung" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="form.neutralleiterabschlaufungObject"
                        :aria-describedby="ariaDescribedby"
                        name="neutralleiterabschlaufung-form"
                        stacked
                    >
                        <b-form-radio
                            v-for="keyValueOption in compNeutralleiterabschlaufungOptions"
                            :key="keyValueOption.keyValueOptionID"
                            :value="keyValueOption"
                            >{{ keyValueOption.text }}</b-form-radio
                        >
                    </b-form-radio-group>
                </b-form-group>
                <!-- Neutralleiterabschlaufung Photo -->
                <PhotoUpload
                    v-show="form.neutralleiterabschlaufungObject && form.neutralleiterabschlaufungObject.text === 'Ja'"
                    :auftragDetail="actAuftragDetail"
                    key="neutralleiterabschlaufungPhoto"
                    entity="devicepool"
                    :entityId="compAttrsDevicePool.devicePoolID"
                    refEntity="auftragdetail"
                    :refEntityId="actAuftragDetail.auftragDetailID"
                    refEntityKey="neutralleiterabschlaufung"
                    folder="devicePools"
                    :fileDescription="
                        `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Neutralleiterabschlaufung`
                    "
                    @assetItems="onAssetItemsNeutralleiterabschlaufung"
                    :captureTypes="['camera', 'gallery']"
                />
                <!-- OffeneSpannnungsFuehrendeTeile -->
                <b-form-group label="Offene spannnungsführende Teile" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="form.offeneSpannnungsFuehrendeTeileObject"
                        :aria-describedby="ariaDescribedby"
                        name="offeneSpannnungsFuehrendeTeile-form"
                        stacked
                    >
                        <b-form-radio
                            v-for="keyValueOption in compOffeneSpannnungsFuehrendeTeileOptions"
                            :key="keyValueOption.keyValueOptionID"
                            :value="keyValueOption"
                            >{{ keyValueOption.text }}</b-form-radio
                        >
                    </b-form-radio-group>
                </b-form-group>
                <!-- Neutralleiterabschlaufung Photo -->
                <PhotoUpload
                    v-show="
                        form.offeneSpannnungsFuehrendeTeileObject &&
                            form.offeneSpannnungsFuehrendeTeileObject.text === 'Ja'
                    "
                    :auftragDetail="actAuftragDetail"
                    key="offeneSpannnungsFuehrendeTeilePhoto"
                    entity="devicepool"
                    :entityId="compAttrsDevicePool.devicePoolID"
                    refEntity="auftragdetail"
                    :refEntityId="actAuftragDetail.auftragDetailID"
                    refEntityKey="offeneSpannnungsFuehrendeTeile"
                    folder="devicePools"
                    :fileDescription="
                        `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Offene Spannnungsführende Teile`
                    "
                    @assetItems="onAssetItemsOffeneSpannnungsFuehrendeTeile"
                    :captureTypes="['camera', 'gallery']"
                />
                <!-- andereGefahren -->
                <b-form-group
                    id="andereGefahren-group"
                    label="Andere Gefahr(en)"
                    label-for="andereGefahren-input"
                    class="mt-2"
                >
                    <b-form-textarea id="andereGefahren-input" v-model="form.andereGefahren" rows="3" />
                </b-form-group>
                <!-- Neutralleiterabschlaufung Photo -->
                <PhotoUpload
                    v-show="form.andereGefahren"
                    :auftragDetail="actAuftragDetail"
                    key="andereGefahrenPhoto"
                    entity="devicepool"
                    :entityId="compAttrsDevicePool.devicePoolID"
                    refEntity="auftragdetail"
                    :refEntityId="actAuftragDetail.auftragDetailID"
                    refEntityKey="andereGefahren"
                    folder="devicePools"
                    :fileDescription="
                        `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Andere Gefahren`
                    "
                    :captureTypes="['camera', 'gallery']"
                />
            </b-form>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import constants from "@/constants/constants";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import { default as devicePoolsAPI, default as devicepoolsApi } from "@/services/api/devicepools.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptions.api";

export default {
    name: "Step_2_3_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        PhotoUpload,
    },
    data() {
        return {
            assetItemsNeutralleiterabschlaufung: { documents: [], images: [] },
            assetItemsOffeneSpannnungsFuehrendeTeile: { documents: [], images: [] },
            devicePool: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            keyValueOptions: [],
            form: {
                asbestObject: null,
                neutralleiterabschlaufungObject: null,
                offeneSpannnungsFuehrendeTeileObject: null,
                andereGefahren: null,
            },
        };
    },
    async mounted() {
        await this.loadAuftragDetail();
        this.keyValueOptions = (await keyValueOptionsAPI.get("devicepool")).data;
        this.devicePool = (await devicepoolsApi.getSingle(this.$route.params.devicePoolId)).data;
        this.loadValue();
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
        }),
        areDangers() {
            // If all checkbox are "Nein"/empty and Andere Gefahren is empty: No Dangers
            if (
                (!this.form.asbestObject || this.form.asbestObject.text === "Nein") &&
                (!this.form.neutralleiterabschlaufungObject ||
                    this.form.neutralleiterabschlaufungObject.text === "Nein") &&
                (!this.form.offeneSpannnungsFuehrendeTeileObject ||
                    this.form.offeneSpannnungsFuehrendeTeileObject.text === "Nein") &&
                !this.form.andereGefahren
            ) {
                return false;
            }
            return true;
        },
        compAsbestOptions() {
            return _.filter(this.keyValueOptions, {
                subcategory: "DangerAsbestKeyValueOptionID",
            });
        },
        compNeutralleiterabschlaufungOptions() {
            return _.filter(this.keyValueOptions, {
                subcategory: "DangerNeutralleiterabschlaufungKeyValueOptionID",
            });
        },
        compOffeneSpannnungsFuehrendeTeileOptions() {
            return _.filter(this.keyValueOptions, {
                subcategory: "DangerOffeneSpannnungsführendeTeileKeyValueOptionID",
            });
        },
        compAttrsDevicePool() {
            return this.$attrs.devicePool;
        },
        compErledigtIsDisabled() {
            return (
                !this.actAuftragDetail.isExecutable ||
                this.status < constants.auftragDetailStatus.DONE_CONDITION_1 ||
                this.status > constants.auftragDetailStatus.DONE_CONDITION_3
            );
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compErledigtIsDisabled) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Lokale Vorbedingungen nicht erfüllt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Lokale Vorbedingungen erfüllt." });
            }

            return preconditionsLocalDetails;
        },
        constants() {
            return constants;
        },
        status() {
            let status = constants.auftragDetailStatus.NOT_STARTED;
            // Check all fields are populated
            if (!this.form.asbestObject) {
                return constants.auftragDetailStatus.NOT_STARTED;
            }
            if (!this.form.neutralleiterabschlaufungObject) {
                return constants.auftragDetailStatus.NOT_STARTED;
            }
            if (!this.form.offeneSpannnungsFuehrendeTeileObject) {
                return constants.auftragDetailStatus.NOT_STARTED;
            }
            // Check images if "Ja"
            if (this.form.neutralleiterabschlaufungObject?.text === "Ja") {
                if (
                    !this.assetItemsNeutralleiterabschlaufung ||
                    this.assetItemsNeutralleiterabschlaufung.images.length === 0
                ) {
                    return constants.auftragDetailStatus.NOT_STARTED;
                }
            }
            if (this.form.offeneSpannnungsFuehrendeTeileObject?.text === "Ja") {
                if (
                    !this.assetItemsOffeneSpannnungsFuehrendeTeile ||
                    this.assetItemsOffeneSpannnungsFuehrendeTeile.images.length === 0
                ) {
                    return constants.auftragDetailStatus.NOT_STARTED;
                }
            }
            // Calculate status 4,5,6
            switch (this.form.asbestObject.text) {
                case "Ja":
                case "Verdacht":
                    status = constants.auftragDetailStatus.DONE_CONDITION_1;
                    break;
                case "Nein":
                    status = constants.auftragDetailStatus.DONE_CONDITION_3;
                    break;
                default:
            }
            return status;
        },
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        getKeyValueOptionsBySubcategory(subcategory) {
            return _.filter(this.keyValueOptions, { subcategory: subcategory });
        },
        getKeyValueOptionsByID(keyValueOptionID) {
            if (!keyValueOptionID) {
                return null;
            }
            return _.find(this.keyValueOptions, { keyValueOptionID: keyValueOptionID });
        },
        async loadAuftragDetail() {
            const pathSplit = this.$route.fullPath.split("/");
            const step = pathSplit[pathSplit.length - 1];
            await auftragDetailsAPI
                .getByOrderIdAndStep(this.$route.params.orderId, step, this.$route.params.devicePoolId)
                .then((res) => {
                    this.setActAuftragDetail(res);
                });
        },
        loadValue() {
            this.form.asbestObject = this.getKeyValueOptionsByID(this.devicePool.asbestKeyValueOptionID);
            this.form.neutralleiterabschlaufungObject = this.getKeyValueOptionsByID(
                this.devicePool.neutralleiterAbschlaufungKeyValueOptionID
            );
            this.form.offeneSpannnungsFuehrendeTeileObject = this.getKeyValueOptionsByID(
                this.devicePool.offeneSpannnungsFuehrendeTeileKeyValueOptionID
            );
            this.form.andereGefahren = this.devicePool.otherDangers;
        },
        onAssetItemsNeutralleiterabschlaufung(items) {
            this.assetItemsNeutralleiterabschlaufung = items;
        },
        onAssetItemsOffeneSpannnungsFuehrendeTeile(items) {
            this.assetItemsOffeneSpannnungsFuehrendeTeile = items;
        },
        goNext() {
            console.log("Are Dangers", this.areDangers);
            if (this.areDangers) {
                // Any dangers? 2-4-1
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            } else {
                // else 2-6-1
                this.navigateToStepNext = this.actAuftragDetail.stepNextFalse;
            }
        },
        async onClickErledigt() {
            await this.save(this.status);
            // Skip
            // Navigate to next step
            if (!this.areDangers) {
                await auftragDetailsAPI.setAuftragDetailSkipped(
                    this.$route.params.orderId,
                    "2-4-1",
                    this.compAttrsDevicePool.devicePoolID
                );
                await auftragDetailsAPI.setAuftragDetailSkipped(
                    this.$route.params.orderId,
                    "2-5-1",
                    this.compAttrsDevicePool.devicePoolID
                );
                console.log("Skipping 2-4-1 and 2-5-1");
            }
            this.goNext();
        },
        async onClickNext() {
            await this.save(); // save but not set status
            this.goNext();
        },
        onClickPrevious(step) {
            // ToDo: do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        async save(status) {
            const patchDoc = [];
            // Save net.DevicePool.* (only if modified)
            if (
                this.form.asbestObject &&
                this.form.asbestObject.keyValueOptionID !== this.devicePool.asbestKeyValueOptionID
            ) {
                patchDoc.push({
                    op: "replace",
                    path: "/asbestKeyValueOptionID",
                    value: this.form.asbestObject.keyValueOptionID,
                });
            }
            if (
                this.form.neutralleiterabschlaufungObject != null &&
                this.form.neutralleiterabschlaufungObject.keyValueOptionID !==
                    this.devicePool.neutralleiterAbschlaufungKeyValueOptionID
            ) {
                patchDoc.push({
                    op: "replace",
                    path: "/neutralleiterAbschlaufungKeyValueOptionID",
                    value: this.form.neutralleiterabschlaufungObject.keyValueOptionID,
                });
            }
            if (
                this.form.offeneSpannnungsFuehrendeTeileObject &&
                this.form.offeneSpannnungsFuehrendeTeileObject.keyValueOptionID !==
                    this.devicePool.offeneSpannnungsFuehrendeTeileKeyValueOptionID
            ) {
                patchDoc.push({
                    op: "replace",
                    path: "/offeneSpannnungsFuehrendeTeileKeyValueOptionID",
                    value: this.form.offeneSpannnungsFuehrendeTeileObject.keyValueOptionID,
                });
            }
            if (this.form.andereGefahren != null && this.form.andereGefahren !== this.devicePool.otherDangers) {
                patchDoc.push({
                    op: "replace",
                    path: "/otherDangers",
                    value: this.form.andereGefahren,
                });
            }
            if (patchDoc.length) {
                // If something is changed update "DangersLastDateCheck" too
                patchDoc.push({
                    op: "replace",
                    path: "/dangersLastDateCheck",
                    value: moment().format(constants.DATETIME_FORMAT_ISO),
                });
                await devicePoolsAPI.patch(this.compAttrsDevicePool.devicePoolID, patchDoc);
            }
            if (this.status !== this.actAuftragDetail.status) {
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, [
                    {
                        op: "replace",
                        path: "/status",
                        value: status ?? constants.auftragDetailStatus.NOT_STARTED,
                    },
                ]);
            }
        },
    },
};
</script>
