<template>
    <Box headerText="Prüfung: REtasks vs Realität">
        <div v-if="isLoadingOrder" class="font-italic">
            <b-spinner v-if="isLoadingOrder" small /> Auftrag wird geladen...
        </div>
        <template v-if="order?.realityCheckCompleted">
            <b-alert show variant="success" class="p-1 my-2 font-italic">
                <font-awesome-icon :icon="['fas', 'circle-check']" /> Prüfung erfolgreich abgeschlossen.
            </b-alert>
            <b-button class="mr-2" variant="primary" :disabled="isBusyAuftragPatch" v-b-modal.check-repeat>
                Prüfung wiederholen
            </b-button>
            <BtnAuftragCreate :auftrag="order" class="mt-2" @poll-data="onBtnAuftragCreatePollData" />
        </template>
        <template v-else>
            <CheckRetasksDbMeters
                :devicepools="devicepools"
                :is-loading-data="isLoadingOrder"
                :is-loading-devicepools="isLoadingDevicepools"
            />
            <hr class="my-2" />
            <template v-if="order?.realityCheckCompleted === false">
                <b-alert show variant="danger" class="p-1 my-2 font-italic">
                    <font-awesome-icon :icon="['fas', 'triangle-exclamation']" /> Prüfung
                    <i>"REtasks vs Realität"</i> war <strong class="font-italic">nicht(!)</strong> erfolgreich!<br />
                    Bitte Daten-Import wiederholen sobald esolva die Daten in IS-E korrigiert hat.<br />
                    <div class="mt-2 font-italic">
                        esolva Backoffice: <PhoneCall :phone-number="esolvaBackofficePhone" />
                    </div>
                </b-alert>
                <div class="d-flex">
                    <b-button class="mr-2" variant="primary" :disabled="isBusyAuftragPatch" v-b-modal.check-repeat>
                        Prüfung wiederholen
                    </b-button>
                    <b-button
                        class="ml-auto"
                        variant="primary"
                        :disabled="isBusyAuftragPatch"
                        v-b-modal.data-import-repeat
                    >
                        Daten-Import wiederholen
                    </b-button>
                    <b-modal
                        id="data-import-repeat"
                        title="Daten-Import wiederholen"
                        cancel-title="Abbrechen"
                        ok-title="Daten-Import wiederholen"
                        @ok="onClickDataImportRepeatOk"
                        :ok-disabled="!compAuftragRealityCheckCompletedChangeAllowed"
                    >
                        Soll der Daten-Import wirklich wiederholt werden?
                        <b-alert
                            v-if="!compAuftragRealityCheckCompletedChangeAllowed"
                            show
                            variant="warning"
                            class="p-1 my-2 font-italic small"
                        >
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                            Der Daten-Import darf für einen Auftrag in Status
                            <strong>{{ order?.auftragStatusID.toUpperCase() }}</strong> nicht durchgeführtwerden.
                            Erlaubt sind: {{ auftragRealityCheckCompletedChangeAllowed.join(", ") }}
                        </b-alert>
                        <template v-else>
                            <div class="font-italic">
                                <font-awesome-icon :icon="['fas', 'info-circle']" /> Ein erneuter Daten-Import sollte
                                erst nach einer Korrektur der Daten durch das esolva Backoffice erfolgen.
                            </div>
                            <div class="mt-2 font-italic">
                                esolva Backoffice: <PhoneCall :phone-number="esolvaBackofficePhone" />
                            </div>
                        </template>
                        <template v-if="techToDosShow">
                            <hr class="my-2" />
                            ToDo(tech):<br />
                            <ul>
                                <li>
                                    welcher import muss wiederholt werden? -> set Auftrag.RealityCheckCompleted=NULL
                                </li>
                            </ul>
                        </template>
                    </b-modal>
                </div>
            </template>
            <template v-else-if="order?.realityCheckCompleted === null">
                <b-alert show variant="warning" class="p-1 my-2 font-italic">
                    Bitte die Angaben prüfen und bestätigen.
                </b-alert>
                <div class="d-flex">
                    <b-button class="mr-2" variant="primary" :disabled="isBusyAuftragPatch" v-b-modal.check-incomplete>
                        Angaben nicht korrekt
                    </b-button>
                    <b-modal
                        id="check-incomplete"
                        title="Angaben nicht korrekt"
                        cancel-title="Abbrechen"
                        ok-title="Angaben nicht korrekt"
                        @ok="onClickCheckIncompleteOk"
                        :ok-disabled="!compAuftragRealityCheckCompletedChangeAllowed"
                    >
                        Sind die Angaben <strong>nicht(!)</strong> korrekt?
                        <b-alert
                            v-if="!compAuftragRealityCheckCompletedChangeAllowed"
                            show
                            variant="warning"
                            class="p-1 my-2 font-italic small"
                        >
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                            Der Auftrag darf in Status <strong>{{ order?.auftragStatusID.toUpperCase() }}</strong> nicht
                            geprüft werden. Erlaubt sind: {{ auftragRealityCheckCompletedChangeAllowed.join(", ") }}
                        </b-alert>
                    </b-modal>
                    <b-button class="ml-auto" variant="primary" :disabled="isBusyAuftragPatch" v-b-modal.check-complete>
                        Angaben korrekt
                    </b-button>
                    <b-modal
                        id="check-complete"
                        title="Angaben korrekt"
                        cancel-title="Abbrechen"
                        ok-title="Angaben korrekt"
                        @ok="onClickCheckCompleteOk"
                        :ok-disabled="!compAuftragRealityCheckCompletedChangeAllowed"
                    >
                        Sind die Angaben korrekt?
                        <b-alert
                            v-if="!compAuftragRealityCheckCompletedChangeAllowed"
                            show
                            variant="warning"
                            class="p-1 my-2 font-italic small"
                        >
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                            Der Auftrag darf in Status <strong>{{ order?.auftragStatusID.toUpperCase() }}</strong> nicht
                            geprüft werden. Erlaubt sind: {{ auftragRealityCheckCompletedChangeAllowed.join(", ") }}
                        </b-alert>
                    </b-modal>
                </div>
            </template>
        </template>
        <!-- shared modals -->
        <b-modal
            id="check-repeat"
            title="Prüfung wiederholen"
            cancel-title="Abbrechen"
            ok-title="Prüfung wiederholen"
            @ok="onClickCheckRepeatOk"
            :ok-disabled="!compAuftragRealityCheckCompletedChangeAllowed"
        >
            Soll die Prüfung wirklich wiederholt werden?
            <b-alert
                v-if="!compAuftragRealityCheckCompletedChangeAllowed"
                show
                variant="warning"
                class="p-1 my-2 font-italic small"
            >
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                Der Auftrag darf in Status <strong>{{ order?.auftragStatusID.toUpperCase() }}</strong> nicht erneut
                geprüft werden. Erlaubt sind: {{ auftragRealityCheckCompletedChangeAllowed.join(", ") }}
            </b-alert>
        </b-modal>
        <template v-if="techToDosShow">
            <!-- ToDo: remove when implemented -->
            <div class="font-italic">
                <hr class="my-2" />
                ToDo(tech): functionalities to implement
                <ul>
                    <li>ETL480: if "Planungsauftrag" -> set Auftrag.RealityCheckCompleted=NULL</li>
                    <li>set isExecutable=FALSE if Auftrag.RealityCheckCompleted=FALSE/NULL</li>
                </ul>
            </div>
        </template>
    </Box>
</template>

<script>
/*
a component to check REtasks (DB) data with reality (before creating the tech. auftrag)
*/
import CheckRetasksDbMeters from "@/components/execution/checks/CheckRetasksDbMeters.vue";
import PhoneCall from "@/components/general/PhoneCall.vue";
import BtnAuftragCreate from "@/components/general/buttons/BtnAuftragCreate.vue";

import auftragsAPI from "@/services/api/auftrags.api";

export default {
    name: "REtasksVsRealityCheck",
    components: { BtnAuftragCreate, CheckRetasksDbMeters, PhoneCall },
    props: {
        devicepools: {
            type: Array,
            default: () => [],
        },
        isLoadingDevicepools: {
            type: Boolean,
            default: true,
        },
        isLoadingOrder: {
            type: Boolean,
            default: true,
        },
        order: Object,
    },
    data() {
        return {
            // auftrag states in which it is allowed to change Auftrag.RealityCheckCompleted
            auftragRealityCheckCompletedChangeAllowed: ["NEW", "CREATED"],
            esolvaBackofficePhone: "+41 81 458 60 90",
            isBusyAuftragPatch: false,
            // ToDo: remove when implemented
            // note: temp. functionality to show tech. todos
            techToDosShow: false,
        };
    },
    computed: {
        compAuftragRealityCheckCompletedChangeAllowed() {
            return this.auftragRealityCheckCompletedChangeAllowed.includes(this.order?.auftragStatusID.toUpperCase());
        },
    },
    methods: {
        auftragRealityCheckCompletedPatch(val) {
            this.isBusyAuftragPatch = true;
            const payload = [
                {
                    op: "replace",
                    path: "/realityCheckCompleted",
                    value: val,
                },
            ];
            return auftragsAPI.patch(this.order.auftragID, payload).finally(() => {
                this.$emit("poll-data", true);
                this.isBusyAuftragPatch = false;
            });
        },
        onBtnAuftragCreatePollData() {
            // note: for simplicity, I emit the same event as for the patch (to update the auftrag data)
            this.$emit("poll-data", true);
        },
        onClickCheckCompleteOk() {
            this.auftragRealityCheckCompletedPatch(1);
        },
        onClickCheckIncompleteOk() {
            this.auftragRealityCheckCompletedPatch(0);
        },
        onClickCheckRepeatOk() {
            this.auftragRealityCheckCompletedPatch(null);
        },
        onClickDataImportRepeatOk() {
            // ToDo: implement functionality
            this.$bvToast.toast("Funktionalität (noch) nicht implementiert.", {
                title: "Info (Daten-Import wiederholen)",
                variant: "warning",
                toaster: "b-toaster-bottom-right",
                noAutoHide: true,
                appendToast: true,
            });
        },
    },
    mounted() {},
};
</script>
