<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <b-spinner v-if="!mainDevice" />
                <div v-else>
                    <exec-meter-info
                        class="mb-2"
                        :mainDevice="mainDevice"
                        :order="order"
                        :devicePool="devicePool"
                        :category="'Wandler'"
                        :showRelatedDeviceInfo="true"
                    />
                    <div>
                        <span>
                            Der von Ihnen erfasste Faktor weicht von dem in der ZFA erfassten Faktor ab! Bitte teilen
                            Sie dies telefonisch Esolva mit:
                        </span>
                    </div>
                    <div>
                        <font-awesome-icon icon="phone" class="mr-2" />
                        <span>
                            <a :href="`tel:${contactEsolva.phoneNumber}`">{{ contactEsolva.phoneNumber }}</a> ({{
                                contactEsolva.name
                            }})
                        </span>
                    </div>
                    <b-form class="mt-2">
                        <b-form-checkbox v-model="esolvaInformedChecked" :disabled="!actAuftragDetail.isExecutable">
                            Esolva wurde informiert.
                        </b-form-checkbox>
                    </b-form>
                </div>
            </div>

            <ExecSectionErledigt
                :isBusy="compIsBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";

import constants from "@/constants/constants";

export default {
    name: "Step_7_3_4",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        ExecMeterInfo,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            device: null,
            esolvaInformedChecked: false,
            isLoading: false,
            isBusy: false,
            mainDevice: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compIsBusy() {
            return this.isBusy;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (!this.esolvaInformedChecked) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Bitte Checkbox 'Esolva wurde informiert' bestätigen.",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Checkbox 'Esolva wurde informiert' bestätigt.",
                });
            }

            return preconditionsLocalDetails;
        },
        contactEsolva() {
            return constants.mainEsolvaContact;
        },
    },
    async mounted() {
        this.isLoading = true;

        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];

        const auftragDetails734 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        await this.setActAuftragDetail(auftragDetails734);
        if (auftragDetails734.value) {
            try {
                const parsedJSON = JSON.parse(auftragDetails734.value);
                this.esolvaInformedChecked = parsedJSON.esolvaInformedChecked;
            } catch (error) {
                const errorMessage = "Die gespeicherten Daten aus Schritt 7-3-4 konnten nicht geladen werden.";
                this.displayToast("DANGER", errorMessage);
            }
        }
        this.device = await devicesAPI
            .getSingle(this.actAuftragDetail.deviceID, { includeDeviceInfos: true })
            .then((resp) => resp.data);

        this.mainDevice = await this.fetchRelatedDeviceDetails();

        if (this.actAuftragDetail.status === constants.auftragDetailStatus.DONE_CLOSED) {
            this.esolvaInformedChecked = true;
        }
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.actAuftragDetail.deviceID,
                devicePoolId: this.$route.params.devicePoolId,
                auftragId: this.$route.params.orderId,
            });
            //The deviceID associated with the auftragDetails of this step
            //is the same as that of the old meter. We therefore retrieve the
            //information from the new installed meter.
            return deviceResp?.data?.newDevice;
        },
        onClickNext() {
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        onClickErledigt() {
            this.isBusy = true;
            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.DONE_CLOSED,
                },
                {
                    op: "replace",
                    path: "/value",
                    value: JSON.stringify({
                        esolvaInformedChecked: this.esolvaInformedChecked,
                    }),
                },
            ];

            auftragDetailsAPI
                .patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail)
                .then(() => {
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        displayToast(variant, message) {
            let noAutoHide = true;
            let title = "Fehler";
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
};
</script>
