<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <b-spinner v-if="!mainDevice" />
                <div v-else>
                    <exec-meter-info
                        class="mb-2"
                        :mainDevice="mainDevice"
                        :order="order"
                        :devicePool="devicePool"
                        :category="'Wandler'"
                        :showRelatedDeviceInfo="true"
                    />
                    <div>
                        <span>
                            Bitte Programmieren Sie die Strom- und Spannungsfaktoren mit dem Parametriertool in den
                            Zähler ein. Stellen Sie sicher, dass auch die Stelligkeiten und die Nachkommastellen am
                            Zählerdisplay korrekt gesetzt werden.
                        </span>
                    </div>
                    <b-form class="mt-2">
                        <b-form-checkbox
                            v-model="correctParameterizationAcknowledged"
                            :disabled="!actAuftragDetail.isExecutable"
                        >
                            {{ this.checkboxLabel }}
                        </b-form-checkbox>
                    </b-form>
                </div>
            </div>

            <ExecSectionErledigt
                :isBusy="compIsBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";

import constants from "@/constants/constants";

export default {
    name: "Step_7_3_5",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        ExecMeterInfo,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            device: null,
            correctParameterizationAcknowledged: false,
            checkboxLabel: "Die korrekte Parametrierung im Zähler wurde vorgenommen.",
            isLoading: false,
            isBusy: false,
            mainDevice: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            rpAuftragItemId: this.$route.params.auftragItemId,
            rpDevicePoolId: this.$route.params.devicePoolId,
            rpOrderId: this.$route.params.orderId,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compIsBusy() {
            return this.isBusy;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (!this.correctParameterizationAcknowledged) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: `Bitte Checkbox '${this.checkboxLabel}' bestätigen.`,
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: `Checkbox '${this.checkboxLabel}' bestätigt.`,
                });
            }

            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        this.isLoading = true;

        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];

        const auftragDetails735 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.rpOrderId,
            step,
            this.rpDevicePoolId,
            null,
            this.rpAuftragItemId
        );
        await this.setActAuftragDetail(auftragDetails735);
        const parsedJSON = JSON.parse(this.actAuftragDetail.value);

        this.correctParameterizationAcknowledged = parsedJSON?.correctParameterizationAcknowledged ?? false;

        this.device = await devicesAPI
            .getSingle(this.actAuftragDetail.deviceID, { includeDeviceInfos: true })
            .then((resp) => resp.data);

        this.mainDevice = await this.fetchRelatedDeviceDetails();

        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.actAuftragDetail.deviceID,
                devicePoolId: this.rpDevicePoolId,
                auftragId: this.rpOrderId,
            });
            //The deviceID associated with the auftragDetails of this step
            //is the same as that of the old meter. We therefore retrieve the
            //information from the new installed meter.
            return deviceResp.data.newDevice;
        },
        onClickNext() {
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        onClickErledigt() {
            this.isBusy = true;
            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.DONE_CLOSED,
                },
                {
                    op: "replace",
                    path: "/value",
                    value: JSON.stringify({
                        correctParameterizationAcknowledged: this.correctParameterizationAcknowledged,
                    }),
                },
            ];

            auftragDetailsAPI
                .patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail)
                .then(() => {
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        displayToast(variant, message) {
            let noAutoHide = true;
            let title = "Fehler";
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
};
</script>
